import React from "react";

import drg_ayu_sukma from "./images/drg-ayu-sukma.jpeg";
import drg_yusuf_wibisono from "./images/drg-yusuf-wibisono.jpeg";
import drg_ratih_permata from "./images/drg-ratih-permata.jpeg";
import drg_efi_sylvina from "./images/drg-efi-sylvina.jpeg";
import drg_boy_zastra from "./images/drg-boy-zastra.jpeg";
import drg_zachra_paramita from "./images/drg-zachra-paramita.jpeg";
import drg_rona_maycittah from "./images/drg-rona-maycittah.jpeg";
import drg_dede_arsista from "./images/drg-dede-arsista.jpeg";
import drg_namira_roshita_h from "./images/drg-namira-roshita-h.jpeg";
import drg_nadhira_haifa from "./images/drg-nadhira-haifa.jpeg";
import drg_fadhila_zhahira from "./images/drg_fadhila_zhahira.jpeg";

import "./TunasDentists.css";

const TunasDentists = () => {
  const listDoctor = [
    {
      doctor_name: "Drg. Ayu Sukma, Sp. Ort.",
      specialist: "Dokter Gigi Spesialis Ortodonti",
      image: drg_ayu_sukma,
    },
    {
      doctor_name: "Drg. Yusuf Wibisono",
      specialist: "Dokter Gigi",
      image: drg_yusuf_wibisono,
    },
    {
      doctor_name: "Drg. Ratih Permata",
      specialist: "Dokter Gigi",
      image: drg_ratih_permata,
    },
    {
      doctor_name: "Drg. Efi Sylvina",
      specialist: "Dokter Gigi",
      image: drg_efi_sylvina,
    },
    {
      doctor_name: "Drg. Boy Zastra",
      specialist: "Dokter Gigi",
      image: drg_boy_zastra,
    },
    {
      doctor_name: "Drg. Zachra Paramita",
      specialist: "Dokter Gigi",
      image: drg_zachra_paramita,
    },
    {
      doctor_name: "Drg. Rona Maycittah",
      specialist: "Dokter Gigi",
      image: drg_rona_maycittah,
    },
    {
      doctor_name: "Drg. Dede Arsista",
      specialist: "Dokter Gigi",
      image: drg_dede_arsista,
    },
    {
      doctor_name: "Drg. Namira Roshita H",
      specialist: "Dokter Gigi",
      image: drg_namira_roshita_h,
    },
    {
      doctor_name: "Drg. Nadhira Haifa",
      specialist: "Dokter Gigi",
      image: drg_nadhira_haifa,
    },
    {
      doctor_name: "Drg. Fadhila Zhahira",
      specialist: "Dokter Gigi",
      image: drg_fadhila_zhahira,
    },
  ];

  return (
    <div className="dentist-tunas-container">
      <h1 className="dentist-tunas-title">Tim Dokter Gigi</h1>
      <div className="dentist-tunas-grid">
        {listDoctor.map((doctor, index) => (
          <div key={index} className="dentist-tunas-card">
            <img src={doctor.image} alt={doctor.doctor_name} />
            <h3>{doctor.doctor_name}</h3>
            <p>{doctor.specialist}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TunasDentists;
