import { React } from "react";
import "./FloatingWhatsappButton.css";
import { FaWhatsapp } from "react-icons/fa";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga4";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../../helper/Firebase";

const FloatingWhatsappButton = () => {
  const handleWhatsAppClick = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "whatsappButtonClicked",
        eventCategory: "WhatsApp",
        eventAction: "Click",
        eventLabel: "Hubungi Kami",
      },
    });

    ReactGA.event({
      category: "user-interaction-button",
      action: "click_whatsapp",
      label: "whatsapp-button",
    });

    logEvent(analytics, "whatsapp_clicked", {
      buttonType: "whatsapp",
      page: window.location.pathname,
    });

    window.open(
      "https://api.whatsapp.com/send?phone=6285100604355&text=Halo%20Admin%20Apotek%20KlinikGigi%20Tunas.%20Saya%20Ingin%20Konsultasi%20di%20Apotek%20Klinik%20Gigi%20Tunas%20ini."
    );
  };

  return (
    <div className="floating-whatsapp-button" onClick={handleWhatsAppClick}>
      <FaWhatsapp className="whatsapp-icon" />
      <span className="contact-text">Hubungi Kami</span>
    </div>
  );
};

export default FloatingWhatsappButton;
