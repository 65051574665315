import React from "react";
import ImageSlider from "../ImageSlider/ImageSlider";

import klinikImage1 from "./images/klinik-gigi-service-1.jpeg";
import klinikImage2 from "./images/klinik-gigi-service-2.jpeg";
import klinikImage3 from "./images/klinik-gigi-service-3.jpeg";
import klinikImage4 from "./images/klinik-gigi-service-4.jpeg";
import klinikImage5 from "./images/klinik-gigi-service-5.jpeg";

import "./DentalService.css";

const DentalService = () => {
  const KlinikImagesData = [
    klinikImage1,
    klinikImage2,
    klinikImage3,
    klinikImage4,
    klinikImage5,
  ];
  return (
    <div className="dental-service-container">
      <h1 className="dental-service-title">Klinik Gigi</h1>
      <div className="row-dental-service">
        <div className="dental-service-image-slider">
          <ImageSlider imageData={KlinikImagesData} />
        </div>

        <div className="dentist-schedule-card ">
          <h2>Jam Praktek</h2>
          <p>
            <strong>Praktek Setiap Hari</strong>
          </p>
          <p>Hari Minggu / Libur Tetap Praktek</p> <br />
          <p>
            <strong>Pagi</strong> : 09.00 – 12.30
          </p>
          <p>
            <strong>Siang</strong> : 13.00 – 17.00
          </p>
          <p>
            <strong>Sore</strong> : 17.00 – 21.30
          </p>
        </div>
      </div>

      <div className="dental-service-card">
        <div className="dental-service-row">
          <div className="dental-service-column">
            <div className="general-dentist-service">
              <h2>Dokter Gigi</h2>
              <div className="divider" />
              <ul>
                <li>
                  <span></span>Pembersihan Karang Gigi
                </li>
                <li>
                  <span></span>Penambalan / Pencabutan Gigi
                </li>
                <li>
                  <span></span>Perawatan Saraf Gigi / Gusi
                </li>
                <li>
                  <span></span>Pembuatan Gigi Palsu
                </li>
                <li>
                  <span></span>Pemutihan Gigi
                </li>
                <li>
                  <span></span>Perawatan Gigi Anak
                </li>
              </ul>
            </div>
          </div>
          <div className="dental-service-column">
            <div className="specialist-dentist-service">
              <h2>Dokter Gigi Spesialis</h2>
              <div className="divider" />
              <ul>
                <li>
                  <span></span>Spesialis Orthodonti (Kawat Gigi)
                </li>
                <li>
                  <span></span>Spesialis Bedah Mulut (Gigi Terbenam, Kista,Tumor
                  Gigi, Implant Gigi)
                </li>
                <li>
                  <span></span>Spesialis Periodonti (Jaringan Penyangga Gigi)
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DentalService;
