import React from "react";
import pharmacyIcon from "../../icon/pharmacy-drug.png";
import dentalCareIcon from "../../icon/dental.png";
import PharmacyService from "./component/PharmacyService/PharmacyService";
import DentalService from "./component/DentalService/DentalService";
import TunasDentists from "./component/TunasDentists/TunasDentists";

import "./ServiceInTunas.css";

const ServiceInTunas = () => {
  return (
    <React.Fragment>
      <div className="layanan-kami-section">
        <h1 className="heading-our-service">Layanan Kami</h1>
        <div className="container-our-service">
          <div className="card-our-service">
            <img src={pharmacyIcon} alt="apotek tunas" />
            <h3>Apotek</h3>
            <p>
              Menyediakan berbagai jenis obat berkualitas, harga bersaing dan
              lengkap
            </p>
          </div>

          <div className="card-our-service">
            <img src={dentalCareIcon} alt="klinik gigi tunas" />
            <h3>Klinik Gigi</h3>
            <p>
              Didukung oleh Tim Medis profesional Dokter Gigi & Dokter Gigi
              Spesialis
            </p>
          </div>
        </div>
      </div>
      <PharmacyService />
      <DentalService />
      <TunasDentists />
    </React.Fragment>
  );
};

export default ServiceInTunas;
