import React from "react";

import halodocLogo from "./images/halodoc-logo.png";
import admedikaLogo from "./images/admedika-logo.png";
import fullertonLogo from "./images/fullerton-logo.png";
import tirtaLogo from "./images/tirta-logo.png";
import GAHLogo from "./images/GAH-logo.png";

import "./OurPartner.css";

const OurPartner = () => {
  const apotekTunasPartners = [
    admedikaLogo,
    fullertonLogo,
    tirtaLogo,
    GAHLogo,
    halodocLogo,
  ];
  return (
    <div className="partner-section">
      <div className="our-partner-container">
        <h2>Partner Kami</h2>
        <p>
          Kami bekerjasama dengan provider asuransi untuk memastikan Anda
          mendapatkan layanan pengobatan dan perawatan gigi di Klinik Gigi Tunas
          dengan cepat melalui aplikasi provider asuransi.
        </p>
        <div className="divider" />
        <div className="our-partner-row">
          {apotekTunasPartners.map((image, index) => (
            <img src={image} alt="logo-partner" />
          ))}
        </div>
      </div>
    </div>
  );
};

export default OurPartner;
