import React, { useState, useEffect } from "react";
import { BsArrowLeftCircleFill, BsArrowRightCircleFill } from "react-icons/bs";

import "./ImageSlider.css";

const ImageSlider = (props) => {
  const [slide, setSlide] = useState(0);

  const nextSlide = () => {
    setSlide(slide === props.imageData.length - 1 ? 0 : slide + 1);
  };

  const prevSlide = () => {
    setSlide(slide === 0 ? props.imageData.length - 1 : slide - 1);
  };

  useEffect(() => {
    const autoSlide = setInterval(() => {
      nextSlide();
    }, 3000);

    return () => {
      clearInterval(autoSlide);
    };
  }, [slide]);

  return (
    <div className="carousel">
      <BsArrowLeftCircleFill onClick={prevSlide} className="arrow arrow-left" />
      {props.imageData.map((item, idx) => {
        return (
          <img
            src={item}
            alt="apotek tunas"
            key={idx}
            className={slide === idx ? "slide" : "slide slide-hidden"}
          />
        );
      })}
      <BsArrowRightCircleFill
        onClick={nextSlide}
        className="arrow arrow-right"
      />
      <div className="indicators">
        {props.imageData.map((_, idx) => {
          return (
            <button
              key={idx}
              className={slide === idx ? "indicator active" : "indicator"}
              onClick={() => setSlide(idx)}
            ></button>
          );
        })}
      </div>
    </div>
  );
};

export default ImageSlider;
