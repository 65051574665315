import React from "react";

import "./CareerInTunas.css";
import careerTunas from "./images/karir-tunas-healthcare.jpeg";
import ModalImage from "react-modal-image";

const CareerInTunas = () => {
  // const apotekTunasCareer = [
  //   {
  //     title: "ADMINISTRASI",
  //     requirements: [
  //       "Lulusan SMK Akutansi",
  //       "Bisa MYOB",
  //       "Wanita, 20 - 30 tahun",
  //       "Jujur dan Bertanggung jawab",
  //     ],
  //   },
  //   {
  //     title: "ASISTEN APOTEKER",
  //     requirements: [
  //       "Lulusan SMK Farmasi",
  //       "Wanita, 20 - 30 tahun",
  //       "Jujur, Bertanggung jawab, dan Komunikatif",
  //       "Diutamakan berpengalaman",
  //     ],
  //   },
  // ];

  return (
    <div className="karir-section">
      <h2>Karir</h2>
      <p>
        Tunas Healthcare membuka peluang karir bagi individu yang memiliki
        kepribadian yang jujur, tanggungjawab, dan komunikatif. Kami mencari
        orang-orang yang dapat memberikan layanan terbaik kepada pasien dengan
        dedikasi dan integritas. Bergabunglah dengan tim kami dan tunjukkan
        potensimu dalam dunia layanan kesehatan gigi yang penuh tantangan.
      </p>
      <div className="divider" />
      <div className="karir-wrapper">
        <ModalImage
          className="image-career"
          small={careerTunas}
          large={careerTunas}
          alt="Karir Tunas Healthcare"
        />
      </div>
      <p className="additional-content">
        Kami sangat menghargai minat Anda untuk bergabung dengan kami. Untuk
        mengirim lamaran, Anda dapat mengirimkan CV dan informasi lengkap Anda
        ke alamat Apotek Klinik Gigi Tunas berikut ini:
      </p>
      <div className="alamat-section">
        <div className="alamat-content">
          <p>Apotek Klinik Tunas</p>
          <br />
          <p>Jl. Bulevar Hijau Raya</p>
          <br />
          <p>Ruko Sentra Niaga Blok B1 No 45 - 46</p>
          <br />
          <p>Kel. Pejuang, Kec. Medan Satria</p>
          <br />
          <p>Kota Bekasi 17131</p>
        </div>
        <a
          href="https://goo.gl/maps/NmZwujcphY4opSLL7"
          className="google-maps-button"
        >
          Lihat di Google Maps
        </a>
      </div>
    </div>
  );
};

export default CareerInTunas;
