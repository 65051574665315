import React from "react";
import apotekTunasFrontView from "../../icon/apotek-tunas-front-view.jpeg";

import "./AboutTunas.css";

const AboutTunas = () => {
  return (
    <div className="tentang-kami-section">
      <div className="row-about-us">
        <div className="image-apotek-tunas-front-view">
          <img src={apotekTunasFrontView} alt="Apotek Tunas" />
        </div>

        <div className="content-about-us">
          <h1 className="heading-about-us">Tentang Kami</h1>
          <p className="subheading-about-us">TUNAS Health Care</p>
          <span>
            • Apotek <br /> • Dokter Gigi <br />• Dokter Gigi Spesialis <br />
            <br />
          </span>
          <p className="description-about-us">
            Berawal dari keinginan untuk memenuhi kebutuhan masyarakat terhadap
            produk obat yang bermutu dan berkualitas, maka tahun 1999 berdirilah
            Apotek Ananda. Dari pengalaman dan dukungan dari berbagai Perusahaan
            Besar Farmasi dan potensi perkembangan ke depannya maka akhir tahun
            2007 dibukalah outlet kedua dengan nama Apotek Tunas di Harapan
            Indah Kota Bekasi. Untuk melengkapi dan meningkatkan pelayanan
            kesehatan kepada masyarakat pada awal tahun 2010 hadir pelayanan
            kesehatan gigi dengan berdirinya Klinik Gigi Tunas yang didukung
            oleh Tim Medis yang profesional dan berdedikasi tinggi.
            <br />
            <br />
            <b>Visi</b>: Menjadi Apotek & Klinik Terkemuka Dalam Pelayanan
            Kesehatan
            <br />
            <b>Misi</b>: Menyelenggarakan pelayanan kesehatan yang bermutu dan
            profesional
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutTunas;
