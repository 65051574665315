import React from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import articleDetail from "../data/detail-article.json";

import "./DetailArticle.css";

const DetailArticle = () => {
  const { id } = useParams();
  const filteredData = articleDetail.find((item) => item.id === id);

  if (!filteredData) {
    return <p>Data tidak ditemukan</p>;
  }

  return (
    <>
      <Helmet>
        {console.log(filteredData.meta)}
        <title>{filteredData.meta.title}</title>
        <meta name="description" content={filteredData.meta.description} />
        <meta name="keywords" content={filteredData.meta.keywords} />
        <meta
          property="og:title"
          content="Kawat Gigi (Behel): Solusi Ampuh Untuk Masalah Gigi Sejak Dulu"
        />
        <meta
          property="og:description"
          content="Ketahui manfaat kawat gigi (behel) untuk merapikan gigi Anda. Pelajari lebih lanjut tentang proses pemasangan, perawatan, dan biaya yang perlu Anda pertimbangkan."
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/dqc8xrmd3/image/upload/v1729349675/mwhuxdh8hpxxpib1cw38.jpg"
        />
        <meta
          property="og:url"
          content="https://www.tunashealthcare.com/article/a2fe47b8-763d-40de-8168-5156ca8bd163"
        />
      </Helmet>
      <div>
        <div dangerouslySetInnerHTML={{ __html: filteredData.content.text }} />
      </div>
    </>
  );
};

export default DetailArticle;
