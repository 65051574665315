import React from "react";
import apotekImage1 from "./images/apotek-tunas-view1.jpeg";
import apotekImage2 from "./images/apotek-tunas-view2.jpeg";
import apotekImage3 from "./images/apotek-tunas-view3.jpeg";
import apotekImage4 from "./images/apotek-tunas-view4.jpeg";
import apotekImage5 from "./images/apotek-tunas-view5.jpeg";

import "./PharmacyService.css";
import ImageSlider from "../ImageSlider/ImageSlider";

const PharmacyService = () => {
  const apotekImagesData = [
    apotekImage1,
    apotekImage2,
    apotekImage3,
    apotekImage4,
    apotekImage5,
  ];

  return (
    <div className="pharmacy-service-container">
      <h1 className="pharmacy-service-title">Layanan Apotek</h1>
      <div className="apotek-image-slider">
        <ImageSlider imageData={apotekImagesData} />
      </div>
      <p className="description-pharmacy-service">
        Menyediakan obat-obatan paten & generik, multivitamin, madu dan herbal,
        infus , obat-obatan bebas <br /> serta alat-alat kesehatan
      </p>
      <div className="divider" />
    </div>
  );
};

export default PharmacyService;
