import React, { useState } from "react";
import apotekTunasLogo from "../../icon/apotek-tunas-logo.png";
import hamburgerButton from "../../icon/hamburger-button.png";
import closeMenuButton from "../../icon/close-menu.png";
import "./Header.css";

const Header = (props) => {
  const [isShowMobileMenu, setShowMenu] = useState(false);

  const showAboutUsMenu = () => {
    setShowMenu(!isShowMobileMenu);
  };

  const onNavigationItemClicked = (sectionData) => {
    if (isShowMobileMenu) {
      setShowMenu(false);
    }
    props.onHeaderNavigationClicked(sectionData);
  };

  return (
    <header>
      <nav className="navbar">
        <img
          src={apotekTunasLogo}
          alt="Apotek Klinik Gigi Tunas"
          className="apotek-tunas-logo"
        />
        <div
          className={`nav-links ${isShowMobileMenu ? "show-mobile-menu" : ""}`}
        >
          <ul>
            <li
              onClick={() => {
                onNavigationItemClicked("tentang-kami-section");
              }}
            >
              Tentang Kami
            </li>
            <li
              onClick={() => {
                onNavigationItemClicked("layanan-kami-section");
              }}
            >
              Layanan Kami
            </li>
            <li
              onClick={() => {
                onNavigationItemClicked("partner-section");
              }}
            >
              Partner
            </li>
            <li
              onClick={() => {
                onNavigationItemClicked("artikel-section");
              }}
            >
              Artikel
            </li>
            <li
              onClick={() => {
                onNavigationItemClicked("karir-section");
              }}
            >
              Karir
            </li>
          </ul>
        </div>
        <img
          src={isShowMobileMenu ? closeMenuButton : hamburgerButton}
          alt="menu-layanan-apotek-tunas"
          className="menu-hamburger"
          onClick={showAboutUsMenu}
        />
      </nav>
      <div
        className={`header-text-container ${
          isShowMobileMenu ? "show-mobile-menu" : ""
        }`}
      >
        <h1>TUNAS Health Care</h1>
        <p>
          Dapatkan Pelayanan Terbaik untuk Obat-Obatan Lengkap Berkualitas,
          Pengobatan yang Profesional, dan Perawatan Gigi Berkualitas Tinggi
          untuk Kesehatan Gigi dan Mulut Optimal Anda.
        </p>
      </div>
    </header>
  );
};

export default Header;
