import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAQ6h8CuB6WqwQNfb1RAK3utC-2aRmd8Zw",
  authDomain: "apotek-klinik-gigi-tunas-3876c.firebaseapp.com",
  projectId: "apotek-klinik-gigi-tunas-3876c",
  storageBucket: "apotek-klinik-gigi-tunas-3876c.appspot.com",
  messagingSenderId: "898961520356",
  appId: "1:898961520356:web:4290fdf578df5f63ffdef1",
  measurementId: "G-MPPDKE677L",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { analytics };
