import React from "react";
import { BiLogoInstagramAlt, BiLogoFacebookCircle } from "react-icons/bi";
import ReactGA from "react-ga4";
import { analytics } from "../../../../helper/Firebase";
import { logEvent } from "firebase/analytics";

import "./FooterTunas.css";

const FooterTunas = () => {
  const handleInstagramClick = () => {
    ReactGA.event({
      category: "user-interaction-button",
      action: "click-instagram",
      label: "instagram-button",
    });

    logEvent(analytics, "instagram_clicked", {
      buttonType: "instagram",
      page: window.location.pathname,
    });

    window.open("https://www.instagram.com/tunashealthcare/");
  };

  const handleFacebookClick = () => {
    ReactGA.event({
      category: "user-interaction-button",
      action: "click-facebook",
      label: "facebook-button",
    });

    logEvent(analytics, "facebook_clicked", {
      buttonType: "facebook",
      page: window.location.pathname,
    });

    window.open(
      "https://www.facebook.com/people/Apotek-Klinik-Gigi-Tunas/100091453947457/?refid=52"
    );
  };

  return (
    <div className="footer-container">
      <div className="social-media-tunas">
        <BiLogoInstagramAlt
          onClick={handleInstagramClick}
          className="instagram-icon"
        />
        <BiLogoFacebookCircle
          onClick={handleFacebookClick}
          className="facebook-icon"
        />
      </div>
      <div className="copyright-text">
        ©2024 – Apotek Klinik Gigi Tunas – All rights reserved
      </div>
    </div>
  );
};

export default FooterTunas;
