import React from "react";
import landingData from "../data/landing_data.json";

import "./article.css";

const ArticleSection = ({ onArticleClick }) => {
  const handleReadMoreClick = (id) => {
    onArticleClick(id);
  };

  return (
    <div className="artikel-section">
      <h2>Artikel Kesehatan Gigi</h2>
      <div className="divider" />
      <div className="article-list">
        {landingData.map((article, index) => (
          <div
            className="card-article-landing"
            key={index}
            onClick={() => handleReadMoreClick(article.id)}
          >
            <img
              className="thumbnail-article-landing"
              src={article.thumbnail}
              alt={article.title}
            />
            <div className="card-content-article-landing">
              <h3 className="article-title-landing">{article.title}</h3>
              <p className="short-description-article-landing">
                {article.short_description}
              </p>
              <button className="read-more-article-landing">
                Baca Selengkapnya
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ArticleSection;
