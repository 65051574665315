import React, { useEffect } from "react";
import Header from "../component/header/Header";
import AboutTunas from "../component/about_tunas/AboutTunas";
import ServiceInTunas from "../component/service_tunas/ServicesInTunas";
import OurPartner from "../component/our_partner/OurPartner";
import CareerInTunas from "../component/career_in_tunas/CareerInTunas";
import FloatingWhatsAppButton from "../component/floating_whatsapp_button/FloatingWhatsappButton";
import FooterTunas from "../component/footer_tunas/FooterTunas";
import ReactGA from "react-ga4";
import ArticleSection from "../component/article/page/article";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

const AboutUs = () => {
  const navigate = useNavigate();
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "About Apotek Klinik Gigi Tunas",
    });
  }, []);

  const onHeaderNavigationClicked = (section) => {
    const targetElement = document.querySelector("." + section);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const onArticleClick = (id) => {
    navigate(`/article/${id}`);
  };

  return (
    <div>
      <Helmet>
        <title>Tentang Apotek Klinik Gigi Tunas</title>
        <meta
          name="description"
          content="TUNAS Healthcare - Dapatkan Pelayanan Terbaik untuk Obat-Obatan Lengkap Berkualitas, Pengobatan yang Profesional, dan Perawatan Gigi Berkualitas Tinggi untuk Kesehatan Gigi dan Mulut Optimal Anda."
        />
        <meta
          name="keywords"
          content="klinik gigi, kawat gigi, behel, perawatan gigi, dokter gigi spesialis, Harapan Indah Bekasi, gigi"
        />
      </Helmet>
      <Header onHeaderNavigationClicked={onHeaderNavigationClicked} />
      <AboutTunas />
      <ServiceInTunas />
      <OurPartner />
      <ArticleSection onArticleClick={onArticleClick} />
      <CareerInTunas />
      <FloatingWhatsAppButton />
      <FooterTunas />
    </div>
  );
};

export default AboutUs;
